<template>
  <div class="operation">
    <ul class="opera-ul">
      <li
        v-for="item in operate"
        :key="item.key"
        @click="handleClick(item)"
        @mouseleave="mouseleave($event, item)"
        @mouseenter="mouseenter($event, item)"
        :class="{ active: item.key === operateKey }"
      >
        <img :src="item.activeIco" width="16px" alt="yes" v-if="operateKey === item.key" />
        <img :src="item.ico" alt="" width="16px" v-else />
        <span>{{ item.str }}</span>
      </li>
    </ul>
    <div class="comment" v-show="operateKey === 'comment'">
      <ul>
        <li v-for="(item, index) in comment" :key="index">
          <div class="head-draw head">{{ lastName(item.name) }}</div>
          <div class="span">
            <span>{{ item.name }}</span>
            <span>{{ parseData(item.ctime) }}</span>
            <span>{{ item.content }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="trajectory" v-show="operateKey === 'trajectory'">
      <table>
        <tr>
          <th>操作时间</th>
          <th>操作人</th>
          <th>操作内容</th>
          <th>操作前</th>
          <th>操作后</th>
        </tr>
        <tr v-for="mark in trajectory" :key="mark.id">
          <td>{{ mark.ctime }}</td>
          <td>
            <div class="head-draw head">{{ lastName(mark.name) }}</div>
            {{ mark.name }}
          </td>
          <td>{{ mark.content }}</td>
          <td>
            <div class="trajectory-content" v-html="mark.before"></div>
          </td>
          <td>
            <div class="trajectory-content" v-html="mark.after"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { postTaskCommentLis, postTaskRecordLis } from '@/service/help';
export default {
  name: 'operate',
  data() {
    return {
      operate: [
        {
          key: 'comment',
          str: '最新进度',
          ico: require('../../../../../assets/image/icon-progress/icon_pl@2x.png'),
          activeIco: require('../../../../../assets/image/icon-progress/icon_pl@2x(1).png'),
        },
        {
          key: 'trajectory',
          str: '操作轨迹',
          ico: require('../../../../../assets/image/icon-progress/icon_czgj@2x.png'),
          activeIco: require('../../../../../assets/image/icon-progress/icon_czgj@2x(1).png'),
        },
      ],
      operateKey: '',
      comment: [
        {
          avatar: '',
          content: '',
          ctime: '',
          name: '',
        },
      ],
      commentKey: '',
      trajectory: [
        {
          after: '',
          avatar: '',
          before: '',
          content: '',
          ctime: '',
          id: '',
          name: '',
          task_id: '',
          uid: '',
        },
      ],
      trajectoryKey: '',
    };
  },
  props: ['id', 'reset', 'record'],
  mounted() {
    this.handleClick(this.operate[0]);
    this.getData();
  },
  methods: {
    getData() {
      this.getComment();
      this.getRecord();
    },
    getComment() {
      //获取评论
      postTaskCommentLis({ task_id: this.id.toString() }).then(res => {
        let data = res.data.data;
        this.comment = data;
      });
    },
    getRecord() {
      //获取操作轨迹
      postTaskRecordLis({ task_id: this.id.toString() }).then(res => {
        let data = res.data.data;
        this.trajectory = data;
      });
    },
    handleClick(item) {
      this.operateKey = item.key;
    },
    mouseenter(event, ico) {
      let target = event.target.firstChild;
      target.setAttribute('src', ico.activeIco);
    },
    mouseleave(event, ico) {
      let target = event.target.firstChild;
      if (target.getAttribute('alt') === 'yes') target.setAttribute('src', ico.activeIco);
      else target.setAttribute('src', ico.ico);
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    parseData(time) {
      return time.substr(5, 2) + '月' + time.substr(8, 2) + '日' + time.substr(11, 5);
    },
  },
  watch: {
    reset: {
      handler() {
        this.getComment();
      },
    },
    record: {
      handler() {
        this.getRecord();
      },
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/operate.less';
</style>
