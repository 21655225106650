<template>
  <div class="workEdit">
    <div class="title">
      <span>实际天数</span>
      <span class="close-ico" @click="$emit('close', index)"></span>
    </div>
    <div class="block">
      <el-date-picker
        v-model="stime"
        type="date"
        placeholder="选择开始日期时间"
        popper-class="date-style"
        prefix-icon="el-icon-date"
        id="stime"
        :picker-options="stimeOptions"
      >
      </el-date-picker>
    </div>
    <div class="block1">
      <el-date-picker
        v-model="etime"
        type="date"
        placeholder="选择结束日期时间"
        prefix-icon="el-icon-date"
        id="etime"
        :picker-options="etimeOptions"
      >
      </el-date-picker>
    </div>
    <div class="span">共{{ workDate }}天</div>
    <div class="describe">
      <textarea type="text" placeholder="请添加描述" v-model="describe"></textarea>
    </div>
    <div class="button">
      <button class="ok" @click="handleOk">确定</button>
      <button class="cancel" @click="$emit('close', index)">取消</button>
    </div>
  </div>
</template>

<script>
import { postTaskWorkTimeMod, postTaskWorkTimeNew } from '@/service/help';
export default {
  name: 'workEdit',
  data() {
    return {
      stime: '',
      etime: '',
      describe: '',
      workDate: 0,
      stimeOptions: {
        disabledDate: time => {
          if (this.etime !== '') {
            return time.getTime() > this.etime;
          }
        },
      },
      etimeOptions: {
        disabledDate: time => {
          if (this.stime !== '') {
            return time.getTime() < this.stime;
          }
        },
      },
    };
  },
  updated() {
    if (typeof this.stime === typeof this.etime && typeof this.stime === 'object') {
      //两个日期都选择的时候开始计算工作日
      let d = this.etime - this.stime; //获得毫秒差，由于这只是日期选择器没有详细时间，所以时分秒都是0：0：0
      this.workDate = d / 86400000; //一天相差86400000毫秒
    }
  },
  props: ['index', 'id'],
  methods: {
    handleOk() {
      if (this.stime === '' || this.etime === '') {
        this.$message({
          type: 'info',
          message: '开始时间和结束时间不能为空!',
        });
        this.$emit('close', this.index);
        return false;
      }
      if (this.index === -1) {
        postTaskWorkTimeNew({
          worktime: this.workDate.toString(),
          content: this.describe,
          task_id: this.id.toString(),
          stime: this.parseData(this.stime),
          etime: this.parseData(this.etime),
        }).then(res => {
          console.log(res);
          this.$emit('close', 'addTime');
        });
      } else {
        postTaskWorkTimeMod({
          worktime: this.workDate.toString(),
          content: this.describe,
          id: this.id,
          stime: this.parseData(this.stime),
          etime: this.parseData(this.etime),
        }).then(res => {
          console.log(res);
          this.$emit('close', this.index);
        });
      }
      /*        console.log(workDate);
        console.log(this.stime);
        console.log(this.etime);
        console.log(this.describe);
        console.log(this.parseData(this.stime));*/
    },
    parseData(data) {
      let y = data.getFullYear();
      let m = data.getMonth() + 1;
      let d = data.getDate();
      let h = data.getHours();
      let mi = data.getMinutes();
      let s = data.getSeconds();
      h = h < 10 ? '0' + h : h;
      mi = mi < 10 ? '0' + mi : mi;
      s = s < 10 ? '0' + s : s;
      return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
    },
  },
  watch: {
    stime: {
      handler(newVal) {
        //做清空操作时重置值
        if (newVal === null) {
          this.stime = '';
          this.workDate = 0;
        }
        /*        else if (typeof this.etime === 'object' && typeof newVal === 'object') {
          //两个日期都选择的时候开始计算工作日
          let d = this.etime - newVal; //获得毫秒差，由于这只是日期选择器没有详细时间，所以时分秒都是0：0：0
          this.workDate = 0;
          let s = newVal.getTime();
          let day = d / 86400000; //一天相差86400000毫秒
          for (let i = 0; i < day; i++) {
            if (new Date(s).getDay() >= 1 && new Date(s).getDay() <= 5) {
              this.workDate++;
            }
            s += 86400000;
          }
        }*/
      },
    },
    etime: {
      handler(newVal) {
        if (newVal === null) {
          this.etime = '';
          this.workDate = 0;
        }
        /*        else if (typeof this.stime === 'object' && typeof newVal === 'object') {
          //两个日期都选择的时候开始计算工作日
          let d = newVal - this.stime; //获得毫秒差，由于这只是日期选择器没有详细时间，所以时分秒都是0：0：0
          this.workDate = 0;
          let s = this.stime.getTime();
          let day = d / 86400000 + 1; //一天相差86400000毫秒
          for (let i = 0; i < day; i++) {
            if (new Date(s).getDay() >= 1 && new Date(s).getDay() <= 5) {
              this.workDate++;
            }
            s += 86400000;
          }
        }*/
      },
    },
  },
};
</script>

<style scoped lang="less">
textarea::-webkit-input-placeholder {
  color: #c2c7d9;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c2c7d9;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c2c7d9;
}
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c2c7d9;
}
.workEdit {
  position: absolute;
  right: -161px;
  width: 540px;
  height: 274px;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(51, 70, 129, 0.16);
  border-radius: 4px;
  .title {
    font-size: 16px;
    color: #334681;
    height: 50px;
    border-bottom: 1px solid #e8ecef;
    line-height: 50px;
    margin-bottom: 20px;
    span {
      margin-left: 20px;
    }
    .close-ico {
      margin-left: 423px;
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../../assets/image/icon-progress/icon_gb.png');
    }
    .close-ico:hover {
      background-image: url('../../../../../assets/image/icon-progress/icon_gb_h.png');
    }
  }
  /deep/.block {
    display: inline-block;
    margin-left: 20px;
    #stime {
      padding-left: 30px;
      padding-right: 0;
      font-size: 13px;
      width: 180px;
      height: 36px;
    }
    #stime:focus + .el-input__prefix {
      color: #13bffa;
    }
    .el-input__prefix {
      left: 150px;
    }
    .el-input__prefix:hover {
      color: #13bffa;
    }
    /*    .el-input__prefix:after{
      content: '';
      width: 17px;
      height: 16px;
      display: inline-block;
      background-image: url("../../../../../assets/image/icon-progress/icon_rl.png");
    }
    .el-input__prefix:hover{
      .el-input__prefix:after{
        background-image: url("../../../../../assets/image/icon-progress/icon_rl_h.png");
      }
    }*/
    .el-input__suffix {
      right: 15px;
    }
  }
  /deep/.block1 {
    display: inline-block;
    margin-left: -15px;
    #etime {
      padding-left: 30px;
      padding-right: 0;
      font-size: 13px;
      width: 180px;
      height: 36px;
    }
    #etime:focus + .el-input__prefix {
      color: #13bffa;
    }
    .el-input__prefix {
      left: 150px;
    }
    .el-input__prefix:hover {
      color: #13bffa;
    }
    .el-input__suffix {
      right: 15px;
    }
  }
  .span {
    display: inline-block;
    margin-left: -15px;
  }
  .describe {
    margin-top: 20px;
    text-align: center;
    textarea {
      width: 500px;
      height: 72px;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #e8ecef;
      border-radius: 4px;
      resize: none;
    }
  }
  .button {
    margin-top: 20px;
    .ok {
      margin-left: 20px;
      width: 94px;
      color: white;
      height: 36px;
      background: #13bfff;
      border-radius: 4px;
      border: none;
    }
    .cancel {
      color: #8590b3;
      margin-left: 16px;
      width: 94px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #e8ecef;
      border-radius: 4px;
    }
  }
}
</style>
