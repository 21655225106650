<template>
  <div class="estimate">
    <div class="sum">
      <span>总登记数次{{ sum }}次</span>
      <span class="add-work-time" @click.stop="show = !show"
        ><img src="../../../../../assets/image/icon-progress/icon_tjfj@2x.png" width="14px" alt="" />添加工时记录</span
      >
      <work-edit v-if="show" :id="id" :index="-1" @close="write($event)" />
    </div>
    <table>
      <tr>
        <th>工时</th>
        <th>描述</th>
        <th>负责人</th>
        <th>时间</th>
        <th>操作</th>
      </tr>
      <tr v-for="(item, index) in timeDetails" :key="index">
        <td>{{ item.worktime }}个工作日</td>
        <td v-if="item.content !== ''">{{ item.content }}</td>
        <td v-else>(无描述)</td>
        <td>
          <div class="head-draw head">{{ lastName(item.name) }}</div>
          <span>{{ item.name }}</span>
        </td>
        <td>{{ parseData(item.ctime) }}</td>
        <td>
          <span class="pencil" @click.stop="item.show = !item.show" /><span
            class="trash"
            @click="delWorkTime(item.id)"
          /><work-edit v-if="item.show" :index="index" :id="item.id" @close="write($event)" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { postTaskWorkTimeDel, postTaskWorkTimeLis } from '@/service/help';
import workEdit from '../compoment/workEdit';
export default {
  name: 'estimate',
  data() {
    return {
      show: false,
      sum: 0,
      timeDetails: [
        {
          avatar: '',
          content: '',
          ctime: '0000-00-00 00:00:00',
          etime: '',
          id: 0,
          name: '某某某',
          stime: '',
          task_id: 0,
          uid: 0,
          worktime: 0,
        },
      ],
    };
  },
  props: ['name', 'id'],
  components: {
    workEdit,
  },
  created() {
    this.getData();
  },
  methods: {
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    parseData(time) {
      return time.substr(5, 2) + '月' + time.substr(8, 2) + '日';
    },
    write(e) {
      console.log('close');
      console.log(e);
      if (e === -1) {
        this.show = !this.show;
      } else if (typeof e === 'number') {
        this.timeDetails[e].show = !this.timeDetails[e].show;
      } else {
        this.show = !this.show;
        this.$emit('ordinary', e);
      }
      this.$nextTick(() => {
        this.getData();
      });
    },
    delWorkTime(id) {
      postTaskWorkTimeDel({
        task_id: this.id.toString(),
        id: id.toString(),
      }).then(res => {
        console.log(res);
        this.$emit('ordinary', 'delTime');
        this.getData();
      });
    },
    getType(obj) {
      //tostring会返回对应不同的标签的构造函数
      let toString = Object.prototype.toString;
      let map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object',
      };
      if (obj instanceof Element) {
        return 'element';
      }
      return map[toString.call(obj)];
    },
    deepClone(data) {
      let type = this.getType(data);
      let obj;
      if (type === 'array') {
        obj = [];
      } else if (type === 'object') {
        obj = {};
      } else {
        //不再具有下一层次
        return data;
      }
      if (type === 'array') {
        for (let i = 0, len = data.length; i < len; i++) {
          obj.push(this.deepClone(data[i]));
        }
      } else if (type === 'object') {
        for (let key in data) {
          obj[key] = this.deepClone(data[key]);
        }
      }
      return obj;
    },
    getData() {
      postTaskWorkTimeLis({ task_id: this.id.toString() }).then(res => {
        let data = res.data.data;
        this.sum = data.length;
        if (this.sum === 0) {
          this.timeDetails = [];
        } else {
          this.timeDetails = this.deepClone(data);
        }
        this.timeDetails.forEach(item => {
          this.$set(item, 'show', false);
        });
      });
    },
    resShow() {
      this.timeDetails.forEach(item => {
        item.show = false;
      });
      this.show = false;
    },
  },
  computed: {
    store() {
      return this.$store.state.taskInfo;
    },
  },
};
</script>

<style scoped lang="less">
.estimate {
  padding: 0 40px;
  .sum {
    color: #8590b3;
    margin: 20px 0;
    .add-work-time {
      line-height: 20px;
      float: right;
      cursor: pointer;
      img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .add-work-time:hover {
    }
  }
  table {
    margin-bottom: 16px;
    text-align: center;
    width: 900px;
    border-top: 1px solid #e8ecef;
    border-left: 1px solid #e8ecef;
    border-spacing: 0;
    th {
      background: #eff3f6;
    }
    td {
      text-align: left;
      padding-left: 10px;
    }
    th,
    td {
      height: 50px;
      border-bottom: 1px solid #e8ecef;
      border-right: 1px solid #e8ecef;
      .head {
        font-size: 12px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
      }
      .pencil {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-left: 40px;
        background-image: url('../../../../../assets/image/icon-progress/icon_pencil@2x.png');
        background-size: cover;
      }
      .pencil:hover {
        background-image: url('../../../../../assets/image/icon-progress/icon_pencil_h@2x.png');
        cursor: pointer;
      }
      .trash {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-left: 30px;
        background-image: url('../../../../../assets/image/icon-progress/icon_trash@2x.png');
        background-size: cover;
      }
      .trash:hover {
        background-image: url('../../../../../assets/image/icon-progress/icon_trash_h@2x.png');
        cursor: pointer;
      }
    }
    th:nth-child(1),
    td:nth-child(1) {
      width: 132px;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 234px;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 200px;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 167px;
    }
    th:nth-child(5),
    td:nth-child(5) {
      width: 167px;
    }
  }
}
</style>
