<template>
  <div>
    <!--微软接口，用来预览office-->
    <iframe
      frameborder="1"
      :src="iframeUrl"
      style="overflow: auto; position: absolute; top: 10%; right: 0; bottom: 0; left: 5%; width: 90%; height: 90%"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'fileView',
  props: ['fileViewUrl','typeFile'],
  data(){
    return{
        iframeUrl:''
    }
  },
  created() {
    if(this.typeFile === 4){ // pdf
      this.iframeUrl = this.fileViewUrl
    }else{
      this.iframeUrl = `https://view.officeapps.live.com/op/view.aspx?src=${this.fileViewUrl}`
    }
    console.log(this.fileViewUrl);
  },
};
</script>

<style scoped lang="less"></style>
