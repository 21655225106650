<template>
  <div class="information" @click="(showMember = false), (showType = false), (showLevel = false)">
    <div class="belong">
      <div class="type">
        <div class="title">任务类型：</div>
        <span @click.stop="ctrlType" v-if="store.type.length>0" class="type-span">{{ store.type.join(',') }}</span>
        <button @click.stop="ctrlType" v-else class="type-span">选择任务类型</button>
        <ul v-show="showType" class="select-type" @click.stop>
          <li v-for="(type, index) in taskType" :key="index" @click="selectType(type)" :class="[{'type-li-active':type.checked}]">{{ type.name }}</li>
        </ul>
      </div>
      <div class="urgency">
        <div class="title">任务紧急度：</div>
        <div @click.stop="ctrlLevel">
          <img :src="levelIco[lev]" width="16px" alt="" v-if="lev >= 0" /><button v-else class="type-span">
            选择紧急度</button
          ><span class="type-span">{{ levels[lev] }}</span>
        </div>
        <ul class="select-level" v-show="showLevel">
          <li v-for="(lIco, index) in levelIco" :key="index" @click.stop="selectLevel(index)">
            <img :src="lIco" alt="" width="16px" /><span>{{ levels[index] }}</span>
          </li>
        </ul>
      </div>

    </div>
    <div class="participants">
      <div class="title">参与人：</div>
      <div class="head-draw member" v-for="item in joinId" :key="item.id" @click.stop="delMember(item.id)">
        {{ lastName(item.name) }}
        <!-- <img class="member" :src="item.avatar" :alt="item.name"> -->
        <span class="del-member el-icon-close" />
      </div>
      <div class="join" @click.stop="showMember = !showMember">
        <div class="block" @click.stop="$event.stopPropagation()">
          <member v-if="showMember" @fromMember="fromMember" :id="id" :userType="userType" :sector_id="sector_id"/>
        </div>
      </div>
    </div>
    <div class="describe">
      <div class="title">描述：</div>
      <div class="editor-s" v-show="!editing">
        <!-- <p>{{ content }}</p> -->
        <div v-html="content" class="editor-main"></div>
        <div class="edit" @click.stop="startEdit()">编辑</div>
      </div>
      <div class="editor" v-show="editing" @click.stop>
        <editor ref="editor" :editorData="describe" @setData="setDescribe" />
        <button class="ok" @click.stop="okHandle">确定</button>
        <button class="cancel" @click.stop="cancleHandle">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  postTaskType,
  postTaskContentMod,
  postTaskJoinDel,
  postTaskJoinNew,
  postTaskLevelMod,
  postTaskTypeMod,
} from '@/service/help';
import editor from '@/components/ckeditor/Editor';
import member from '@/components/task/progress/member';
import { tasklevel } from '@/common/js';
export default {
  name: 'information',
  data() {
    return {
      editing: false,
      taskType: [],
      levelIco: [
        require('../../../../../assets/image/icon-progress/icon_jjd_1@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_2@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_3@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_4@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_5@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_6@2x.png'),
      ],
      describe: '',
      content: '',
      showMember: false,
      joinId: [],
      showLevel: false,
      showType: false,
      lev: Number,
    };
  },
  props: ['name', 'id', 'cont', 'join_id', 'level', 'userType', 'sector_id'],
  components: {
    member,
    editor,
  },
  mounted() {
    this.content = this.cont;
    console.log(this.userType,'====userType====')
    this.getData();
  },
  methods: {
    getData() {
      postTaskType().then(res => {
        if(res.data.success){
          this.taskType = res.data.data.map(v=>{
              let obj = {}
              obj['name'] = v
              obj['checked'] = false
              this.store.type.map(v2=>{
                 if(v == v2){
                  obj['checked'] = true
                 }
              })
              return obj
          })
        }
        
      });
    },
    setDescribe(val) {
      this.describe = val;
    },
    startEdit() {
      this.describe = this.content;
      this.editing = !this.editing;
    },
    okHandle() {
      let describe = this.describe;
      postTaskContentMod({
        content: this.describe,
        id: this.id.toString(),
      }).then(res => {
        if (res.data.success) {
          this.content = describe;
          this.$emit('ordinary');
        }
      });
      this.editing = !this.editing;
    },
    cancleHandle() {
      this.editing = !this.editing;
      this.describe = '';
    },
    lastName(name) {
      if (typeof name !== 'undefined' && name !== '') {
        return name.charAt(name.length - 2) + name.charAt(name.length - 1);
      }
    },
    fromMember(name, id) {
      this.showMember = false;
      postTaskJoinNew({
        id: this.id.toString(),
        join_id: id.toString(),
        avatar: '',
      }).then(res => {
        console.log(res.data);
        this.joinId.push({ name: name, id: id, avatar: '' });
        this.$emit('ordinary');
      });
    },
    delMember(id) {
      postTaskJoinDel({
        id: this.id.toString(),
        join_id: id.toString(),
      }).then(() => {
        this.joinId = this.joinId.filter(item => {
          return item.id !== id.toString();
        });
        this.$emit('ordinary');
      });
    },
    ctrlType() {
      this.showType = !this.showType;
    },
    ctrlLevel() {
      this.showLevel = !this.showLevel;
    },
    selectType(type) { // 多选任务类型
      type.checked = !type.checked
      
    },
    closeTaskType(){ // 关闭任务类型弹窗
      let typeData = []
      this.taskType.forEach(item => {
          if(item.checked){
            typeData.push(item.name)
          }
      });
      postTaskTypeMod({ type: typeData, id: this.id.toString() }).then(() => {
        this.$emit('ordinary');
      });
      this.store.type = typeData;
    },
    selectLevel(index) {
      postTaskLevelMod({ level: index.toString(), id: this.id.toString() }).then(() => {
        this.$emit('ordinary');
      });
      this.lev = index;
    },
  },
  computed: {
    store() {
      return this.$store.state.taskInfo;
    },
    levels() {
      return tasklevel.map(v => {
        return v.value;
      });
    },
  },
  watch: {
    showType:{
      handler(newVal,oldVal){
        if(!newVal) this.closeTaskType()
      }
    },
    cont: {
      handler() {
        this.content = this.cont;
      },
    },
    level: {
      handler(newVal) {
        this.lev = parseInt(newVal);
      },
      immediate: true,
    },
    join_id: {
      handler(newVal) {
        this.joinId = typeof newVal === 'string' ? [] : newVal;
      },
      deep: true,
      immediate: true,
    },
    /*    showMember:{
      handler(newVal) {
        console.log(newVal);
      }
    }*/
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/information.less';
</style>
