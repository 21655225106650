<template>
  <div class="enclosure" @click.stop="resetShow">
    <div class="sum">
      <div class="sum-top">
        总{{ sum }}个附件

        <button class="plane" v-if="$store.state.planeImgs.length > 0" @click="openPlane">画板展示</button>
        <span class="add" @click.stop="showUpLoad = !showUpLoad"> <span class="add-ico" /><span>添加附件</span></span>
        <span class="download" @click="downloadAll" v-show="sum > 1"
          ><span class="download-ico" /><span>全部下载</span></span
        >
      </div>

      <ul class="upload" v-show="showUpLoad">
        <li @click="$refs.upload.click()">
          本地上传<input v-show="false" type="file" ref="upload" @change="changeFile($event)" />
        </li>
        <li>网盘上传</li>
      </ul>
    </div>
    <!--    <img :src="file" alt="aaa">-->
    <div class="source">
      <ul class="enclosure-ul">
        <!--        <li v-for="(item, index) in source" :key="index" @contextmenu.prevent="resetShow(), (item.show = true)">-->
        <li
          class="enclosure-li"
          v-for="(item, index) in source"
          :key="index"
          @mouseenter.stop="mouseenter(index)"
          @mouseleave.stop="mouseleave(index)"
        >
          <img
            v-image-preview
            @load="imgLoad($event)"
            width="110px"
            height="50px"
            :src="item.url"
            v-if="typeFile(item.filename) === 0"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_w.png"
            v-else-if="typeFile(item.filename) === 1"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_x.png"
            v-else-if="typeFile(item.filename) === 2"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_p.png"
            v-else-if="typeFile(item.filename) === 3"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_pdf.png"
            v-else-if="typeFile(item.filename) === 4"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_ys.png"
            v-else-if="typeFile(item.filename) === 8"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_wz.png"
            v-else-if="typeFile(item.filename) === 999"
          />
          <span
            v-else
            @click="fileViewUrl = item.url"
            style="width: 110px; height: 50px; display: inline-block; border: 1px solid #e8ecef; text-align:center;line-height:50px"
            >未知文件</span
          >
          <div class="source-details">
            <span>{{ item.filename }}</span
            ><br />
            <span class="size">{{ item.size }}来自{{ item.name }}</span>
            <span class="data">{{ parseData(item.ctime) }}</span>
          </div>
          <div v-show="item.show" class="mouse-show">
            <ul class="delOrDown">
              <li @click="down(item)"><div class="ico-xzfj" /></li>
              <!--下载-->
              <li @click="del(item.id)"><div class="ico-trash" /></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="fileViewFrame" v-if="fileViewUrl !== ''">
      <span class="close-ico" @click="fileViewUrl = ''"></span>
      <file-view :file-view-url="fileViewUrl" :type-file="typeFile(fileViewUrl)" @setFileUrlNull="fileViewUrl = ''" />
    </div>
  </div>
</template>

<script>
import {
  postTaskDownload,
  postTaskDownloadAll,
  postTaskUploadDel,
  postTaskUploadLis,
  postTaskUploadNew,
  uploadFile,
} from '@/service/help';
import { dataURLtoBlob, suffix, typeFile } from '@/utils/utils';
import fileView from '@/components/task/progress/detail/compoment/fileView';
// import axios from 'axios';

export default {
  name: 'enclosure',
  data() {
    return {
      sum: 0,
      source: [
        {
          name: '',
          ctime: '',
          filename: '',
          id: 0,
          size: '',
          task_id: 0,
          uid: 0,
          url: '',
          show: false,
        },
      ],
      showUpLoad: false,
      file: '',
      downUrl: '',
      fileViewUrl: '',
      img: [],
      planeImgSum: 0,
    };
  },
  props: ['name', 'id', 'taskTitle'],
  components: {
    fileView,
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.state.Bus.$on('addPlane', s => {
      this.$refs.upload.click();
    });
    this.$store.state.Bus.$on('downImg', id => {
      let item = this.source.find(v => (v.id = id));
      this.down(item);
    });
    this.$store.state.Bus.$on('delImg', id => {
      // let item = this.source[i];
      this.del(id);
      
    });
    this.$store.state.Bus.$on('update', () => {
      this.getData();
    });
  },
  destroyed() {
    this.$store.state.Bus.$off('addPlane');
    this.$store.state.Bus.$off('downImg');
    this.$store.state.Bus.$off('delImg');
    this.$store.state.Bus.$off('update');
  },
  methods: {
    imgLoad() {},
    getData() {
      postTaskUploadLis({
        task_id: this.id.toString(),
      }).then(res => {
        let data = res.data.data;
        this.source = data;
        this.sum = this.source.length;
        this.source.forEach(item => {
          this.$set(item, 'show', false);
        });
        let pImgs = data.filter(v => {
          return typeFile(v.filename) == 0;
        });
        this.planeImgSum = pImgs.length;
        this.$store.commit('set', { type: 'planeImgs', data: pImgs });
      });
    },
    changeFile(e) {
      let a = e.target.value;
      let name = a.slice(a.lastIndexOf('\\') + 1);
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let _this = this;
      reader.onload = function (e) {
        //这里的this指向函数
        _this.file = this.result;
        let fd = new FormData();
        let blob = dataURLtoBlob(e.target.result); //转blob
        if (blob.size > 10733741824) {
          alert('所传文件不能大于1G');
          return false;
        }
        let f = new File([blob], name, { type: blob.type });
        fd.append('uploadfile', f); //数据
        let size = blob.size / 1024;
        if (size > 1024) {
          size = (size / 1024).toFixed(2) + 'm';
        } else {
          size = size.toFixed(2) + 'k';
        }
        // axios({
        //   url: 'https://up.eai9.com', //请求上传文件返回url
        //   method: 'post',
        //   data: fd,
        //   headers: {
        //     'content-type': 'multipart/form-data',
        //   },
        // })
        uploadFile(fd).then(res => {
          let url = res.data.data;
          console.log(url);
          postTaskUploadNew({
            //成功取到url传给后台
            url: url.toString(),
            size: size,
            task_id: _this.id.toString(),
            filename: name.toString(),
            uid: _this.$store.state.uid,
          }).then(() => {
            _this.$emit('ordinary', 'addSource');
            _this.getData();
          });
        });
      };
      this.showUpLoad = false;
    },
    downloadAll() {
      postTaskDownloadAll({ task_id: this.id.toString() }).then(res => {
        let url = res.data.data; //url
        this.downloadFile(url, this.taskTitle);
      });
    },
    down(item) {
      postTaskDownload({ id: item.id }).then(res => {
        let data = res.data.data;
        /*        this.downUrl = data.url.replace('https://oss.eai9.com', 'download');*/
        this.downUrl = data.url;
        this.downloadFile(this.downUrl, item.filename);
      });
    },
    del(id) {
      this.$confirm('确认要删除该附件吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      }).then(() => {
        postTaskUploadDel({ id: id }).then(() => {
          this.$emit('ordinary', 'delSource');
          this.getData();
        });
      })
    },
    resetShow() {
      this.source.forEach(item => {
        item.show = false;
      });
      this.showUpLoad = false;
    },
    parseData(time) {
      return time.substr(5, 2) + '月' + time.substr(8, 2) + '日' + time.substr(11, 5);
    },
    mouseenter(index) {
      this.$nextTick(() => {
        this.source[index].show = true;
      });
    },
    mouseleave(index) {
      this.$nextTick(() => {
        this.source[index].show = false;
      });
    },
    downloadFile(url, name) {
      if (typeFile(name) === 0) {
        //文件是图片的情况
        let type = suffix(name);
        this.ImgtodataURL(url, name, type);
      } else {
        //原本的下载方法
        /*        window.location.href = url;*/
        try {
          let elemIF = document.createElement('a');
          elemIF.href = url;
          elemIF.style.display = 'none';
          elemIF.download = name;
          document.body.appendChild(elemIF);
          elemIF.click();
          document.body.removeChild(elemIF);
        } catch (e) {
          console.log(e);
        }
      }
    },
    typeFile(fileName) {
      //判断文件类型方法
      return typeFile(fileName);
    },
    exl(u, n) {
      const url = u;
      // window.open(_this.detail.imgUrl)
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.open('GET', url, true);
      xmlhttp.responseType = 'blob';
      xmlhttp.onload = function () {
        if (this.status === 200) {
          const blob = this.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          // link.download = url.substring(url.lastIndexOf("/") + 1, url.length);
          link.download = n;
          //此写法兼容可火狐浏览器
          document.body.appendChild(link);
          const evt = document.createEvent('MouseEvents');
          evt.initEvent('click', false, false);
          link.dispatchEvent(evt);
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      };
      xmlhttp.send();
    },
    ImgtodataURL(url, filename, fileType) {
      // this.getImg(url);
      this.getBase64(url, fileType, _baseUrl => {
        // 创建隐藏的可下载链接
        var eleLink = document.createElement('a');
        eleLink.download = filename;
        eleLink.style.display = 'none';
        // 图片转base64地址
        eleLink.href = _baseUrl;
        eleLink.setAttribute('href', _baseUrl);
        console.log('_baseUrl', _baseUrl);
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        // 然后移除
        document.body.removeChild(eleLink);
      });
    },
    getBase64(url, fileType, callback) {
      //通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片
      var Img = document.createElement('img'),
        dataURL = '';
      console.log(url);
      console.log('临时创建的Img', Img);
      Img.onload = function () {
        //要先确保图片完整获取到，这是个异步事件
        var canvas = document.createElement('canvas'), //创建canvas元素
          width = Img.width, //确保canvas的尺寸和图片一样
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        console.log('canvas', canvas);
        var ctx = canvas.getContext('2d');
        ctx.drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
        dataURL = canvas.toDataURL('image/' + fileType); //转换图片为dataURL
        console.log('dataURL', dataURL);
        callback ? callback(dataURL) : null;
      };
      Img.setAttribute('crossOrigin', 'Anonymous');
      Img.setAttribute('src', url); //访问跨域图片
    },
    getImg(url) {
      console.log(url);
      // axios({
      //   url: url, //请求上传文件返回url
      //   method: 'get',
      //   headers: {
      //     Origin: 'https://oss.eai9.com',
      //     withCredentials: false,
      //   },
      // }).then(res => {
      //   console.log(res);
      // });
    },
    openPlane() {
      //打开画板
      this.$store.commit('set', { type: 'showPlane', data: true });
    },
  },
  watch: {
    '$store.state.addPlane': {
      handler(val) {
        if (val) {
          alert(val);
          this.$refs.upload.click();
          this.$store.commit('set', { type: 'addPlane', data: false });
        }
      },
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/enclosure';
</style>
