<template>
  <div class="cover" @click="$event.stopPropagation()">
    <div class="menu-container">
      <ul class="menu">
        <li @click="setImgs">新增图片</li>
        <li @click="close">退出画板</li>
      </ul>
    </div>
    <div class="top-center">{{ scale * 100 }}%;x:{{ x }};y:{{ y }}</div>
    <canvas
      @click="canvasClick"
      @contextmenu.stop.prevent="canvasMenu"
      id="myCas"
      :width="width"
      :height="height"
      :style="{ width: width + 'px', height: height + 'px' }"
      ref="myCas"
      class="plane-cas"
    ></canvas>
    <canvas
      id="upCas"
      :width="width"
      :height="height"
      :style="{ width: width + 'px', height: height + 'px', display: 'none' }"
      ref="upCas"
    ></canvas>
    <ul class="canvas-menu" v-if="casMenu.show" :style="{ left: casMenu.x + 'px', top: casMenu.y + 'px' }">
      <li @click.stop="delImg">删除</li>
      <li @click.stop="downImg">下载</li>
    </ul>
  </div>
</template>

<script>
import { postTaskUploadFix } from '@/service/help';
import { deepClone } from '@/utils/utils';
export default {
  name: 'plane',
  props: {},
  data() {
    return {
      canvas: null,
      ctx: null,
      upcas: null,
      upctx: null,
      width: 6000, //1920
      height: 6000, //935
      scale: 1,
      modIco: require('./../../../../../assets/image/icon-progress/icon_bj.png'),
      startX: 100, //离屛开始扫描x坐标
      startY: 200, ////离屛开始扫描y坐标
      area: {
        x: 200,
        y: 200,
        w: 0,
        h: 0,
      },
      sel_area: { x: 0, y: 0, w: 0, h: 0, select: false },
      hov_area: { x: 0, y: 0, w: 0, h: 0 },
      wsPos: null,
      wnPos: { x: 0, y: 0 },
      wheelscalc: 1,
      ismove: false,
      imgs: [],
      x: 0,
      y: 0,
      casMenu: {
        x: 300,
        y: 300,
        show: false,
      },
    };
  },
  mounted() {
    this.canvas = document.getElementById('myCas');
    this.ctx = this.canvas.getContext('2d');
    this.upcas = document.getElementById('upCas');
    this.upctx = this.upcas.getContext('2d');
    this.canvasEventsInit();
  },
  methods: {
    upctxRedraw() {
      this.imgs.forEach(v => {
        this.drawImg(v);
      });
    },
    canvasEventsInit() {
      window.addEventListener('mousewheel', this.windowMousewheel, { passive: false });
      let canvas = this.canvas;
      window.addEventListener('keydown', this.windowKeydown);
      window.addEventListener('keyup', this.windowKeyup);
      canvas.onmousemove = this.canvasMouseMove;
      canvas.onmousedown = evt => {
        //按下
        let pos = undefined,
          posc = undefined;
        canvas.style.cursor = 'move';
        this.ismove = true;
        canvas.onmousemove = evt => {
          //移动
          canvas.style.cursor = 'move';
          var posl = { x: evt.clientX, y: evt.clientY };
          if (!pos) {
            pos = posl;
            posc = { x: this.area.x, y: this.area.y };
          }
          var x = Number(posc.x) + (Number(posl.x) - Number(pos.x));
          var y = Number(posc.y) + (Number(posl.y) - Number(pos.y));
          this.update(x, y);
        };
        canvas.onmouseup = () => {
          //松开
          canvas.onmousemove = this.canvasMouseMove;
          canvas.onmouseup = null;
          canvas.style.cursor = 'default';
        };
      };
    },
    windowToCanvas(x, y) {
      let canvas = this.canvas;
      let box = canvas.getBoundingClientRect(); //这个方法返回一个矩形对象，包含四个属性：left、top、right和bottom。分别表示元素各边与页面上边和左边的距离
      return {
        x: x - box.left - (box.width - canvas.width) / 2,
        y: y - box.top - (box.height - canvas.height) / 2,
      };
    },
    drawImage(img, w, h, x, y) {
      if (typeof arguments[1] == 'object') {
        let obj = arguments[1];
        w = obj.w;
        h = obj.h;
        x = obj.x;
        y = obj.y;
      }
      this.upctx.drawImage(img, x, y, w, h);
      this.update();
    },
    removeEvent() {
      window.removeEventListener('mousewheel', this.windowMousewheel);
      window.removeEventListener('keydown', this.windowKeydown);
      window.removeEventListener('keyup', this.windowKeyup);
    },
    canvasWheel(event) {
      let pos = this.windowToCanvas(event.clientX, event.clientY);
      if (!this.wsPos || this.wnPos.x !== pos.x || this.wnPos.y !== pos.y) {
        this.wnPos = pos;
        this.wsPos = { x: this.area.x, y: this.area.y };
        this.wheelscalc = this.scale;
      }
      if (event.wheelDelta > 0) {
        this.scale *= 1.25;
      } else {
        this.scale /= 1.25;
      }
      //限制this.scale大小
      if (this.scale < 0.01) {
        this.scale = 0.01;
      } else if (this.scale > 16) {
        this.scale = 16;
      }
      if (Math.round(this.scale * 1000) == 1000) {
        this.scale = 1;
      }
      let x = pos.x + ((this.wsPos.x - pos.x) * this.scale) / this.wheelscalc;
      let y = pos.y + ((this.wsPos.y - pos.y) * this.scale) / this.wheelscalc;
      // let x = pos.x;
      // let y = pos.y;
      this.update(x, y);
    },
    canvasClick(event) {
      //点击
      let x = event.clientX;
      let y = event.clientY;
      this.casMenu.show = false;
      if (typeof this.sel_area.hi == 'number') {
        if (this.sel_area.hov_name == '改名') {
          this.imgRename();
        } else if (this.sel_area.hov_name == '图片') {
          this.drawImg(this.imgs[0]); //不知道什么原因第一张清不了
          if (typeof this.sel_area.si == 'number') {
            this.drawImg(this.imgs[this.sel_area.si]);
          }
          let img = this.imgs[this.sel_area.hi];
          this.selectDraw(img.x - 2, img.y - 2, img.w + 4, img.h + 4, '#30a0be', this.upctx);
          this.sel_area.si = this.sel_area.hi;
        }
      } else {
        this.drawImg(this.imgs[0]); //不知道什么原因第一张清不了
        if (this.sel_area.si) {
          this.drawImg(this.imgs[this.sel_area.si]);
          delete this.sel_area.si;
        }
        return;
      }
    },
    canvasMenu(event) {
      let x = event.clientX;
      let y = event.clientY;
      if (typeof this.sel_area.hi == 'number') {
        if (this.sel_area.hov_name == '图片') {
          this.casMenu.x = x;
          this.casMenu.y = y;
          this.casMenu.show = true;
        } else {
          this.casMenu.show = false;
        }
      } else {
        this.casMenu.show = false;
      }
    },
    canvasMouseMove(evt) {
      //移动
      var posl = { x: evt.clientX, y: evt.clientY };
      this.canvas.style.cursor = 'default';
      posl.x = (posl.x - this.area.x) / this.scale + this.startX;
      posl.y = (posl.y - this.area.y) / this.scale + this.startY;
      if (this.isClick(posl.x, posl.y, this.hov_area)) {
        this.canvas.style.cursor = 'pointer';
        this.x = posl.x;
        this.y = posl.y;
        return;
      } else {
        if (this.sel_area.hov_name == '改名') {
          // this.x = 0
          // this.y = 0
          this.drawImg(this.imgs[this.sel_area.hi]); //恢复
        }
        for (let i = 0, len = this.imgs.length; i < len; i++) {
          let info = this.imgs[i];
          if (this.isClick(posl.x, posl.y, info.x - 24, info.y - 24, 20, 20)) {
            //悬停在修改名字上
            let img1 = new Image();
            img1.src = require('./../../../../../assets/image/icon-progress/icon_bj(1).png');
            img1.onload = () => {
              this.drawImage(img1, 20, 20, info.x - 24, info.y - 24);
            };
            this.setPos('hov_area', { x: info.x - 24, y: info.y - 24, w: 20, h: 20 });
            this.canvas.style.cursor = 'pointer';
            this.sel_area.hi = i;
            this.sel_area.hov_name = '改名';
            break;
          } else if (this.isClick(posl.x, posl.y, info)) {
            //悬停在图片上
            this.canvas.style.cursor = 'pointer';
            this.sel_area.hi = i;
            this.sel_area.hov_name = '图片';
            this.setPos('hov_area', info);
            break;
          } else {
            this.canvas.style.cursor = 'default';
            this.setPos('hov_area');
            delete this.sel_area.hov_name;
          }
        }
      }

      // this.x = posl.x
      // this.y = posl.y
    },
    windowMousewheel(event) {
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault();
      }
    },
    windowKeyup(event) {
      if (event.key == 'Control') {
        this.canvas.onmousewheel = this.canvas.onwheel = function() {
          this.wsPos = null;
        };
        this.canvas.onmousemove = this.canvasMouseMove;
      }
    },
    windowKeydown(event) {
      if (event.ctrlKey === true && this.canvas.onwheel != this.canvasWheel) {
        this.canvas.onmousewheel = this.canvas.onwheel = this.canvasWheel;
        this.setPos('hov_area');
        this.canvas.onmousemove = () => {};
      }
    },
    close() {
      this.removeEvent();
      this.$store.state.Bus.$emit('update');
      this.$store.commit('set', { type: 'showPlane', data: false });
    },
    initImgs() {
      let imgsInit = this.$store.state.planeImgs.map(v => {
        let obj = deepClone(v);
        return this.initImg(obj).then(img => {
          obj.w = img.width;
          obj.h = img.height;
          obj.img = img;
          return obj;
        });
      });
      Promise.all(imgsInit)
        .then(resimgList => {
          this.upctx.clearRect(0, 0, this.upcas.width, this.upcas.height);
          let padding = 50;
          let margin = 50;
          let naw = 3000; //新的总宽度
          try {
            //利用错误回滚
            this.imgs = getImgs.call(this);
          } catch (e) {
            if (e == 'reset') {
              this.upctx.clearRect(0, 0, this.upcas.width, this.upcas.height);
              this.imgs = getImgs.call(this);
            } else {
              throw new Error();
            }
          }
          function getImgs() {
            let cx = this.startX + padding,
              cy = this.startY + padding,
              aw = this.area.w,
              ah = this.area.h;
            return resimgList.map(v => {
              let obj = v,
                img = v.img;
              obj.x = cx;
              obj.y = cy;
              if (cx + img.width + margin + padding < naw) {
                //不换行
                cx = cx + img.width + margin;
                if (cy + img.height > ah) {
                  //有更高的图片改变ah
                  ah = cy + img.height;
                }
                aw = cx;
              } else {
                if (img.width + this.startX + padding > naw) {
                  //某张大图片宽度都超过预先设定的宽度
                  naw = img.width + this.startX + 500;
                  throw 'reset';
                }
                aw = naw;
                cy = ah + margin;
                ah = cy + img.height + margin;
                cx = this.startX + img.width + margin + padding;
                obj.x = this.startX + padding;
                obj.y = cy;
              }
              if (aw > this.area.w) {
                this.area.w = aw;
              }
              if (ah > this.area.h) {
                this.area.h = ah;
              }
              if (aw > this.width - 1000) {
                this.width = aw + 100;
              }
              if (this.area.h > this.height - 1000) {
                this.height = this.area.h + 1000;
              }
              return obj;
            });
          }

          this.$nextTick(() => {
            console.log(this.area.w);
            this.upctxRedraw();
          });
        })
        .catch(e => {
          //所有加载异常处理在此
          this.$message({
            type: 'error',
            message: '图片加载过程发生异常',
          });
          this.close();
        });
    },
    initImg(imgInfo) {
      let img = new Image();
      return new Promise((resolve, reject) => {
        img.onload = () => {
          resolve(img);
        };
        img.onerror = e => {
          reject(e);
        };
        img.setAttribute('src', imgInfo.url);
      });
    },
    drawImg(obj) {
      this.upctx.clearRect(obj.x - 30, obj.y - 30, obj.w + 50, obj.h + 50);
      // this.upctx.clearRect(obj.x - 24, obj.y - 24, obj.w + 24, obj.h + 24);
      let img1 = new Image();
      img1.src = this.modIco;
      img1.onload = () => {
        this.drawImage(img1, 20, 20, obj.x - 24, obj.y - 24);
      };
      this.upctx.fillStyle = '#334681';
      this.upctx.textBaseline = 'top';
      this.upctx.font = '20px';
      obj.type = obj.filename.split('.')[1];
      obj.fname = obj.filename.split('.')[0];
      this.upctx.fillText(obj.fname, obj.x, obj.y - 20);
      obj.textWidth = this.upctx.measureText(obj.filename.split('.')[0]).width;
      this.drawImage(obj.img, obj);
    },
    update(x, y) {
      if (!x) {
        x = this.area.x;
      }
      if (!y) {
        y = this.area.y;
      }
      let ctx = this.ctx;
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      ctx.fillStyle = '#F6BA36';
      this.area.x = Number(x);
      this.area.y = Number(y);
      // x = x - (this.area.w / 2) * this.scale;
      // y = y - (this.area.h / 2) * this.scale;
      // ctx.fillRect(x, y, this.area.w * this.scale, this.area.h * this.scale);
      if (this.scale >= 1) {
        ctx.drawImage(
          this.upcas,
          this.startX,
          this.startY,
          this.area.w,
          this.area.h,
          x,
          y,
          this.area.w * this.scale,
          this.area.h * this.scale,
        );
      } else {
        ctx.drawImage(
          this.upcas,
          this.startX,
          this.startY,
          this.area.w / this.scale,
          this.area.h / this.scale,
          x,
          y,
          this.area.w,
          this.area.h,
        );
      }

      // this.ctx.drawImage(this.upcas,
      // x,y,this.area.w*this.scale,this.area.h*this.scale)
    },
    setImgs() {
      // this.$store.commit('set', { type: 'addPlane', data: true });
      this.$store.state.Bus.$emit('addPlane');
    },
    isClickArea(x, y) {
      return this.isClick(x, y, this.area.x, this.area.y, this.area.w * this.scale, this.area.h * this.scale);
    },
    isClick(x, y, ax, ay, aw, ah) {
      if (typeof arguments[2] == 'object') {
        let obj = arguments[2];
        aw = obj.w;
        ah = obj.h;
        ax = obj.x;
        ay = obj.y;
      }
      let status = false;
      if (x >= ax && x <= ax + aw && y >= ay && y <= ay + ah) {
        status = true;
      }
      return status;
    },
    selectDraw(x, y, w, h, color, ctx) {
      ctx.strokeStyle = color;
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x + w, y);
      ctx.lineTo(x + w, y + h);
      ctx.lineTo(x, y + h);
      ctx.closePath();
      ctx.stroke();
      this.update();
    },
    imgRename() {
      //修改图片名字弹窗
      this.$prompt('', '重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(({ value }) => {
          value = value.trim();
          let imgInfo = this.imgs[this.sel_area.hi];
          let type = imgInfo.type;
          postTaskUploadFix({ filename: value + '.' + type, id: imgInfo.id })
            .then(res => {
              if (res.data.success) {
                this.$message({
                  type: 'success',
                  message: '新图片名是: ' + value,
                });
                this.imgs[this.sel_area.hi].filename = value + '.' + type;
                this.drawImg(this.imgs[this.sel_area.hi]);
              } else {
                throw new Error('网络请求失败');
              }
            })
            .catch(e => {
              this.$message({
                type: 'error',
                message: e,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    setPos(target, x, y, w, h) {
      if (typeof arguments[1] == 'object') {
        let obj = arguments[1];
        x = obj.x;
        y = obj.y;
        w = obj.w;
        h = obj.h;
      }
      if (arguments[1]) {
        this[target].x = x;
        this[target].y = y;
        this[target].w = w;
        this[target].h = h;
      } else {
        this[target].x = 0;
        this[target].y = 0;
        this[target].w = 0;
        this[target].h = 0;
        if (target == 'hov_area') {
          delete this.sel_area.hi;
          delete this.sel_area.hov_name;
        }
      }
    },
    downImg() {
      this.canvasMenu.show = false;
      let id = this.imgs[this.sel_area.hi].id;
      this.$store.state.Bus.$emit('downImg', id);
    },
    delImg() {
      this.canvasMenu.show = false;
      let id = this.imgs[this.sel_area.hi].id;
      this.$store.state.Bus.$emit('delImg', id);
    },
    test() {},
  },
  computed: {
    pImgs() {
      return this.$store.state.planeImgs;
    },
  },
  watch: {
    '$store.state.planeImgs': {
      handler() {
        this.initImgs();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="less">
.cover {
  z-index: 20;
  position: fixed;
  background: rgb(240, 242, 245);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .menu-container {
    padding-top: 60px;
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    width: 300px;
    height: 400px;
    transition: clip-path 0.5s ease-out 0.3s;
    background-image: url('../../../../../assets/image/icon-progress/icon_rwxi@2x.png');
    background-size: 36px 36px;
    background-position: 24px 24px;
    background-repeat: no-repeat;
    cursor: pointer;
    clip-path: circle(28px at 44px 44px);
    &:hover {
      transition: clip-path 0.5s ease-out;
      clip-path: circle(460px at 44px 44px);
      background-image: url('../../../../../assets/image/icon-progress/icon_rwxi_h@2x.png');
    }
    .menu {
      width: 100%;
      padding: 16px;
      li {
        width: 100%;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 16px;
        margin-bottom: 16px;
        color: #334681;
        &:hover {
          cursor: pointer;
          color: #46d8ff;
          background: #f2f9ff;
        }
      }
    }
  }
  .top-center {
    width: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    z-index: 10000;
  }
  .plane-cas {
    width: 100%;
    height: 100%;
  }

  #myCas {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: blue; */
    /* z-index: 10; */
  }
  #upCas {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: red; */
    /* background-color: red; */
  }
  .canvas-menu {
    background-color: #fff;
    border-radius: 8px;
    width: 80px;
    overflow: hidden;
    position: absolute;
    z-index: 10000;
    li {
      width: 100%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      color: #334681;
      &:not(li:last-child) {
        margin-bottom: 8px;
      }
      &:hover {
        cursor: pointer;
        color: #46d8ff;
        background: #f2f9ff;
      }
    }
  }
}
</style>
